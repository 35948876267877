<template>
    <autocomplete
        v-model="client"
        label="Клиент"
        item-label="fio"
        placeholder="Поиск клиента"
        :items="clients"
        :multiple="false"
        @async="autocomplete"
        @select-item="selectItem"
    >
        <template v-slot:result-item="data">
            <div class="order-client">
                <div class="fio">{{ data.item['fio'] }}</div>
                <div class="phone" v-if="data.item['phone']">
                    {{ data.item['phone'] }}
                </div>
            </div>
        </template>
    </autocomplete>
</template>

<script>
import { ClientsService } from "../../../services/clients.service";
import Autocomplete from "../Autocomplete";

export default {
    name: "AutocompleteClients",
    components: {Autocomplete},
    props: {
        modelValue: {
            type: [Number, null],
        },
    },
    methods: {
        autocomplete(value) {
            this.service.autocomplete({
                filters: {
                    query: value
                }
            }).then(response => {
                if (response.data) {
                    this.clients = response.data;
                }
            })
        },
        selectItem(client) {
            this.$emit('selectItem', client);
            this.$emit('update:modelValue', client ? client.id : null);
        },
    },
    emits: ['update:modelValue', 'selectItem'],
    watch: {
        modelValue(value) {
            if (value === null) {
                this.client = null;
            } else {
                if (!this.client || this.client.id !== value) {
                    this.service.one({
                        filters: {
                            id: value
                        }
                    }).then(response => {
                        this.client = response.data;
                    })
                }
            }
        }
    },
    data() {
        return {
            service: ClientsService,
            clients: [],
            client: null,
        }
    }
}
</script>

<style scoped>

</style>