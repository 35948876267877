<template>
    <div class="history">
        <div v-for="(item, index) in items" :key="index" class="item">
            <div class="date">
                <div class="day">{{ item.day }}</div>
                <div class="time">{{ item.time }}</div>
            </div>
            <div class="description">
                <div v-html="item.description"></div>
                <div class="manager" v-if="item['manager_name']">
                    <b>Менеджер</b>: {{ item['manager_name'] }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "History",
    props: {
        items: {
            type: Array,
            default: () => {
            }
        }
    }
}
</script>
