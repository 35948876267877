<template>
    <label class="input-box" :class="inputClasses">
        <div class="label" v-if="label.length">{{ label }}</div>
        <select
            v-model="value"
        >
            <option v-for="(val, key) in options" :key="key" :value="key">
                {{ val }}
            </option>
        </select>
    </label>
</template>

<script>
export default {
    name: "SelectBox",
    props: {
        modelValue: {
            type: [String, Number],
            default: ""
        },
        options: {
            type: [Object, Array],
            default: () => {}
        },
        label: {
            type: String,
            default: ""
        },
        type: {
            type: String,
            default: "text"
        },
        placeholder: {
            type: String,
            default: ""
        },
        success: Boolean,
        error: Boolean,
    },
    emits: ['update:modelValue'],
    computed: {
        value: {
            get() {
                return this.modelValue
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        },
        inputClasses() {
            return {
                'with-label': !!this.label,
                'filled': !!this.modelValue,
                'success': this.success,
                'has-error': this.error
            }
        },
    }
}
</script>
