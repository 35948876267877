<template>
    <listing
        :items="items"
        :pagination="pagination"
        :fields="fields"
    >
        <template v-slot:head>
            <th class="column-number">Номер</th>
            <th class="nowrap">Статус</th>
            <th class="main-cell">Описание</th>
        </template>
        <template v-slot:row="data">
            <td>{{ data.item.id }}</td>
            <td class="nowrap">{{ statusesList[data.item['status_id']] }}</td>
            <td>{{ data.item.description }}</td>
        </template>
    </listing>
</template>

<script>
import Listing from "../listing/Listing";
import { DictionariesService } from "../../services/dictionaries.service";

export default {
    name: "OrdersListing",
    components: {Listing},
    props: {
        items: Array,
        fields: Array,
        pagination: Object,
    },
    computed: {
        statusesList() {
            let list = {};
            if (this.dictionariesService.statuses) {
                this.dictionariesService.statuses.map(status => {
                    list[status.id] = status.name;
                });
            }

            return list;
        },
    },
    data() {
        return {
            dictionariesService: DictionariesService
        }
    }
}
</script>

<style scoped>

</style>