import { reactive } from "vue";
import HttpService from "./http.service";

export const ClientsService = reactive({
    state: [],
    autocomplete(data) {
        return HttpService.get('clients/autocomplete', data);
    },
    get(data) {
        return HttpService.get('clients/all', data);
    },
    one(data) {
        return HttpService.get('clients/one', data);
    },
    save(data) {
        return HttpService.post('clients/save', data);
    },
    remove(data) {
        return HttpService.post('clients/remove', data);
    }
})