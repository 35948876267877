import { reactive } from "vue";
import HttpService from "./http.service";

export const OrdersService = reactive({
    state: [],
    get(data) {
        return HttpService.get('orders/all', data);
    },
    one(data) {
        return HttpService.get('orders/one', data);
    },
    save(data) {
        return HttpService.post('orders/save', data);
    },
    remove(data) {
        return HttpService.post('orders/remove', data);
    }
})