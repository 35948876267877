<template>
    <modal ref="editor">
        <editor
            :title="editor.title"
            :errors="editor.errors"
            :item="editor.item"
            :id="editor.id"
            @save="saveItem"
            @close="closeEditor"
        >
            <editor-tab title="Информация">
                <div class="info">
                    <div class="fields">
                        <div class="field">
                            <select-box
                                v-model="editor.item['status_id']"
                                label="Статус"
                                :options="statusesList"
                            ></select-box>
                        </div>
                        <div class="field">
                            <autocomplete-clients
                                v-model="editor.item['client_id']"
                                @select-item="selectClient"
                            ></autocomplete-clients>
                        </div>
                        <div class="field">
                            <input-box
                                v-model="editor.item['fio']"
                                label="Контактное ФИО"
                            ></input-box>
                        </div>
                        <div class="field">
                            <input-box
                                v-model="editor.item['phone']"
                                label="Контактный телефон"
                            ></input-box>
                        </div>
                        <div class="field">
                            <input-box
                                v-model="editor.item['email']"
                                label="Контактный E-mail"
                            ></input-box>
                        </div>
                        <div class="field">
                            <input-box
                                v-model="editor.item['description']"
                                label="Описание"
                            ></input-box>
                        </div>
                    </div>
                </div>
            </editor-tab>

            <editor-tab title="Товары">
                <div class="content">
                    <catalog-list
                        :items="catalogList"
                        @change-list="changeCatalogList"
                    ></catalog-list>
                </div>
            </editor-tab>

            <editor-tab title="История">
                <div class="content">
                    <history :items="editor.item['history']"></history>
                </div>
            </editor-tab>

        </editor>
    </modal>
    <div class="content">
        <div class="title">Заказы</div>
        <orders-listing
            :loading="loading"
            :items="listing.items"
            :pagination="listing.pagination"
            :fields="listing.fields"
            @change-page="changePage"
            @add-item="addItem"
            @edit-item="editItem"
            @remove-item="removeItem"
            @apply-filters="loadItems"
            @close-filters="loadItems"
            @change-query="changeQuery"
        ></orders-listing>
    </div>
</template>

<script>
import { OrdersService } from "../services/orders.service";
import { ClientsService } from "../services/clients.service";
import { DictionariesService } from "../services/dictionaries.service";
import History from "../components/History";
import Modal from "../components/Modal";
import Editor from "../components/editor/Editor";
import EditorTab from "../components/editor/EditorTab";
import SelectBox from "../components/fields/SelectBox";
import InputBox from "../components/fields/InputBox";
import CatalogList from "../components/catalog/CatalogList";
import AutocompleteClients from "../components/fields/autocomplete/AutocompleteClients";
import OrdersListing from "../components/orders/OrdersListing";

export default {
    name: "Orders",
    components: {
        OrdersListing,
        AutocompleteClients,
        History, CatalogList, InputBox, SelectBox, EditorTab, Editor, Modal
    },
    methods: {
        loadItems() {
            this.loading = true;
            this.ordersService.get(this.listingParams).then(response => {
                this.listing.items = response.data;
                this.listing.pagination = response.pagination;
                this.loading = false;
            });
        },
        changePage(page) {
            this.listing.page = page;
        },
        addItem() {
            this.editor.title = 'Добавить заказ';
            this.editor.item = {
                catalog: [],
                client_id: null,
            };
            this.editor.id = null;
            this.client = null;
            this.$refs.editor.show();
        },
        editItem(id) {
            this.editor.id = id;
            this.ordersService.one({
                filters: {
                    id: this.editor.id
                }
            }).then(response => {
                this.editor.title = 'Редактировать заказ №' + id;
                this.editor.item = response.data;
                this.$refs.editor.show();
            });
        },
        removeItem(id) {
            this.ordersService.remove({
                filters: {
                    id: id
                }
            }).then(response => {
                if (response.success) {
                    this.loadItems();
                }
            });
        },
        saveItem(response) {
            this.ordersService.save(response).then(response => {
                if (response.success === true) {
                    this.loadItems();
                    this.$refs.editor.hide();
                    this.editor.id = null;
                } else {
                    this.editor.errors = response.errors;
                }
            });
        },
        selectClient(client) {
            if (client) {
                if (!this.editor.item.fio) {
                    this.editor.item.fio = client.fio;
                }
                if (!this.editor.item.phone) {
                    this.editor.item.phone = client.phone;
                }
                if (!this.editor.item.email) {
                    this.editor.item.email = client.email;
                }

            }
        },
        closeEditor() {
            this.$refs.editor.hide();
        },

        changeCatalogList(catalog) {
            this.editor.item['catalog'] = catalog;
        },
        changeQuery(query) {
            this.listing.query = query;
        }
    },
    computed: {
        listingParams() {
            let params = {
                page: this.listing.page,
                filters: [],
                order: 'id',
                by: 'DESC'
            };
            if (this.listing.query) {
                params.filters.id = this.listing.query;
            }

            return params;
        },
        statusesList() {
            let list = {};
            if (this.dictionariesService.statuses) {
                this.dictionariesService.statuses.map(status => {
                    list[status.id] = status.name;
                });
            }

            return list;
        },
        catalogList() {
            return this.editor.item && this.editor.item.catalog
                ? this.editor.item.catalog
                : []
        },
    },
    created() {
        this.loadItems();
    },
    data() {
        return {
            ordersService: OrdersService,
            clientsService: ClientsService,
            dictionariesService: DictionariesService,
            clients: [],
            client: null,
            loading: true,
            listing: {
                items: [],
                fields: [],
                page: 1,
                pagination: {},
            },
            editor: {
                id: null,
                item: {
                    catalog: [],
                },
                title: '',
                errors: [],
            }
        }
    }
}
</script>

<style scoped>

</style>