<template>
    <div class="catalog-list">
        <div class="listing">
            <div class="filters">
                <autocomplete
                    placeholder="Поиск товаров"
                    item-label="name"
                    v-model="autocomplete"
                    :items="catalog"
                    :multiple="false"
                    @async="autocompleteCatalog"
                >
                    <template v-slot:extended>
                        <div class="extended">
                            <select v-model="store">
                                <option
                                    v-for="store in dictionariesService.stores"
                                    :key="store.id"
                                    :value="store.id"
                                >
                                    {{ store.name }}
                                </option>
                            </select>
                        </div>
                    </template>
                </autocomplete>
            </div>
            <table>
                <thead>
                <tr>
                    <th class="column-number">#</th>
                    <th>Товар</th>
                    <th class="column-input">Цена</th>
                    <th class="column-counter">Кол-во</th>
                    <th class="column-price">Итого</th>
                    <th class="column-action"></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, index) in list" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>
                        <div>{{ item['catalog_name'] }}</div>
                        <div class="tags">
                            <div class="tag">{{ getStoreName(item['store_id']) }}</div>
                            <div
                                class="tag grey"
                                v-for="modifier in item['modifiers']"
                                :key="modifier.id"
                            >
                                {{ modifier.name }} x {{ modifier.count }}
                            </div>
                        </div>
                    </td>
                    <td>
                        <input-box v-model="item.price"></input-box>
                    </td>
                    <td>
                        <counter
                            v-model="item.count"
                            :min="item.min"
                            :max="item.max"
                        ></counter>
                    </td>
                    <td>
                        {{ item.price * item.count }}
                    </td>
                    <td>
                        <div class="actions">
                            <div class="button remove" @click="removeItem(index)">удл</div>
                        </div>
                    </td>
                </tr>
                <tr class="footer">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td><b>Кол-во: {{ totalCount }}</b></td>
                    <td><b>{{ totalPrice }}</b></td>
                    <td></td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { DictionariesService } from "../../services/dictionaries.service";
import { CatalogService } from "../../services/catalog.service";
import { AuthService } from "../../services/auth.service";
import Autocomplete from "../fields/Autocomplete";
import Counter from "../fields/Counter";
import InputBox from "../fields/InputBox";

export default {
    name: "CatalogList",
    props: {
        items: {
            type: Array,
            default: () => []
        }
    },
    components: {InputBox, Counter, Autocomplete},
    methods: {
        autocompleteCatalog(value) {
            this.catalogService.autocomplete({
                filters: {
                    query: value
                }
            }).then(response => {
                if (response.data) {
                    this.catalog = response.data;
                }
            })
        },
        getStoreName(storeId) {
            return this.dictionariesService.stores.find(store => store.id === storeId)
                ? this.dictionariesService.stores.find(store => store.id === storeId).name
                : '[не найден]';
        },
        removeItem(index) {
            this.list.splice(index, 1);
        }
    },
    computed: {
        totalCount() {
            let total = 0;
            this.list.map(item => total += item.count);

            return total;
        },
        totalPrice() {
            let total = 0;
            this.list.map(item => total += item.count * item.price);

            return total;
        }
    },
    watch: {
        autocomplete(value) {
            if (value) {
                this.catalogService.one({
                    filters: {
                        id: value.id
                    }
                }).then(response => {
                    const target = this.list
                        .find(item => {
                            return item.catalog_id === response.data.id &&
                                item.store_id === this.store
                        });
                    if (target) {
                        console.log('Товар уже добавлен')
                    } else {
                        const count = response.data.stores[this.store] > 0 ? 1 : 0;
                        this.list.push({
                            catalog_id: response.data.id,
                            catalog_name: response.data.name,
                            store_id: this.store,
                            count: count,
                            min: count,
                            max: response.data.stores[this.store],
                            price: response.data.prices[this.authService.getPriceId()],
                        });
                    }
                    this.autocomplete = null;
                })
            }
        },
        items(value) {
            this.list = value;
        },
        list: {
            deep: true,
            handler() {
                this.$emit('changeList', this.list);
            }
        },
        authService: {
            deep: true,
            handler() {
                this.store = this.authService.getStoreId();
            }
        }
    },
    created() {
        this.list = this.items;
        this.store = this.authService.getStoreId();
    },
    data() {
        return {
            authService: AuthService,
            catalogService: CatalogService,
            dictionariesService: DictionariesService,
            store: null,
            list: [],
            catalog: [],
            autocomplete: null
        }
    }
}
</script>

<style scoped>

</style>